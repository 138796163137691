// Level 1.
import level1_drag1 from '../src/images/level1/bessen-triplets.png';
import level1_drag2 from '../src/images/level1/boterham.png';
import level1_drag3 from '../src/images/level1/kaaskop.png';
import level1_drag4 from '../src/images/level1/tomaatje.png';
import level1_drop1 from '../src/images/level1/vakje_kaas.png';
import level1_drop2 from '../src/images/level1/vakje_tomaat.png';
import level1_drop3 from '../src/images/level1/vakje_bes.png';
import level1_drop4 from '../src/images/level1/vakje_brood.png';

// Level 5.
import level5_drag1 from '../src/images/level2/_framboos.png';
import level5_drag2 from '../src/images/level2/_mes.png';
import level5_drag3 from '../src/images/level2/_brood.png';
import level5_drag4 from '../src/images/level2/_prei.png';
import level5_drop1 from '../src/images/level2/_wortelen.png';
import level5_drop2 from '../src/images/level2/peer.png';
import level5_drop3 from '../src/images/level2/_vork.png';
import level5_drop4 from '../src/images/level2/_kaasplakken.png';

// Level 4.
import level4_drag1 from '../src/images/level3/eggs.png';
import level4_drag2 from '../src/images/level3/peer2.png';
import level4_drag3 from '../src/images/level3/yoghurt.png';
import level4_drag4 from '../src/images/level3/soep.png';
import level4_drop1 from '../src/images/level3/smiley.png';
import level4_drop2 from '../src/images/level3/sadface.png';

// Level 3.
import level3_drag1 from '../src/images/level4/_ei.png';
import level3_drag2 from '../src/images/level4/_melk.png';
import level3_drag3 from '../src/images/level4/_aardappel.png';
import level3_drag4 from '../src/images/level4/_kers.png';
import level3_drop1 from '../src/images/level4/_kip.png';
import level3_drop2 from '../src/images/level4/_koe.png';
import level3_drop3 from '../src/images/level4/flower_field.jpg';
import level3_drop4 from '../src/images/level4/grass_field.jpg';

// Level 2.
import level2_drag1 from '../src/images/level5/kaas_part.png';
import level2_drag2 from '../src/images/level5/aardbei_part.png';
import level2_drag3 from '../src/images/level5/appel_part.png';
import level2_drag4 from '../src/images/level5/bloemkool_part.png';
import level2_drop1 from '../src/images/level5/bloemkool.png';
import level2_drop2 from '../src/images/level5/kaas.png';
import level2_drop3 from '../src/images/level5/aardbei.png';
import level2_drop4 from '../src/images/level5/appel.png';

// Level 6.
import level6_drag1 from '../src/images/level6/_water.png';
import level6_drag2 from '../src/images/level6/_cola.png';
import level6_drag3 from '../src/images/level6/_fruitsap.png';
import level6_drag4 from '../src/images/level4/_melk.png';
import level6_drop1 from '../src/images/level6/thumbdown.png';
import level6_drop2 from '../src/images/level6/thumbup.png';

export const DnDTypes = {
    DRAG: 'drag',
    DROP: 'drop',
};

export const LEVEL1_DRAG = [
    {
        id: 1,
        img: level1_drag1,
        target: 7,
        canDrag: true,
    },
    {
        id: 2,
        img: level1_drag2,
        target: 8,
        canDrag: true,
    },
    {
        id: 3,
        img: level1_drag3,
        target: 5,
        canDrag: true,
    },
    {
        id: 4,
        img: level1_drag4,
        target: 6,
        canDrag: true,
    },
];

export const LEVEL1_DROP = [
    {
        id: 5,
        img: level1_drop1,
        canDrop: true,
    },
    {
        id: 6,
        img: level1_drop2,
        canDrop: true,
    },
    {
        id: 7,
        img: level1_drop3,
        canDrop: true,
    },
    {
        id: 8,
        img: level1_drop4,
        canDrop: true,
    },
];

export const LEVEL2_DRAG = [
    {
        id: 1,
        img: level2_drag1,
        target: 6,
        canDrag: true,
    },
    {
        id: 2,
        img: level2_drag2,
        target: 7,
        canDrag: true,
    },
    {
        id: 3,
        img: level2_drag3,
        target: 8,
        canDrag: true,
    },
    {
        id: 4,
        img: level2_drag4,
        target: 5,
        canDrag: true,
    },
];

export const LEVEL2_DROP = [
    {
        id: 5,
        img: level2_drop1,
        canDrop: true,
    },
    {
        id: 6,
        img: level2_drop2,
        canDrop: true,
    },
    {
        id: 7,
        img: level2_drop3,
        canDrop: true,
    },
    {
        id: 8,
        img: level2_drop4,
        canDrop: true,
    },
];

export const LEVEL4_DRAG = [
    {
        id: 1,
        img: level4_drag1,
        target: 6,
        canDrag: true,
    },
    {
        id: 2,
        img: level4_drag2,
        target: 6,
        canDrag: true,
    },
    {
        id: 3,
        img: level4_drag3,
        target: 5,
        canDrag: true,
    },
    {
        id: 4,
        img: level4_drag4,
        target: 5,
        canDrag: true,
    },
];

export const LEVEL4_DROP = [
    {
        id: 5,
        img: level4_drop1,
        canDrop: true,
    },
    {
        id: 6,
        img: level4_drop2,
        canDrop: true,
    },
];

export const LEVEL3_DRAG = [
    {
        id: 1,
        img: level3_drag1,
        target: 5,
        canDrag: true,
    },
    {
        id: 2,
        img: level3_drag2,
        target: 6,
        canDrag: true,
    },
    {
        id: 3,
        img: level3_drag3,
        target: 7,
        canDrag: true,
    },
    {
        id: 4,
        img: level3_drag4,
        target: 8,
        canDrag: true,
    },
];

export const LEVEL3_DROP = [
    {
        id: 5,
        img: level3_drop1,
        canDrop: true,
    },
    {
        id: 6,
        img: level3_drop2,
        canDrop: true,
    },
    {
        id: 7,
        img: level3_drop3,
        canDrop: true,
    },
    {
        id: 8,
        img: level3_drop4,
        canDrop: true,
    },
];

export const LEVEL5_DRAG = [
    {
        id: 1,
        img: level5_drag1,
        target: 6,
        canDrag: true,
    },
    {
        id: 2,
        img: level5_drag2,
        target: 7,
        canDrag: true,
    },
    {
        id: 3,
        img: level5_drag3,
        target: 8,
        canDrag: true,
    },
    {
        id: 4,
        img: level5_drag4,
        target: 5,
        canDrag: true,
    },
];

export const LEVEL5_DROP = [
    {
        id: 5,
        img: level5_drop1,
        canDrop: true,
    },
    {
        id: 6,
        img: level5_drop2,
        canDrop: true,
    },
    {
        id: 7,
        img: level5_drop3,
        canDrop: true,
    },
    {
        id: 8,
        img: level5_drop4,
        canDrop: true,
    },
];

export const LEVEL6_DRAG = [
    {
        id: 1,
        img: level6_drag1,
        target: 6,
        canDrag: true,
    },
    {
        id: 2,
        img: level6_drag2,
        target: 5,
        canDrag: true,
    },
    {
        id: 3,
        img: level6_drag3,
        target: 5,
        canDrag: true,
    },
    {
        id: 4,
        img: level6_drag4,
        target: 6,
        canDrag: true,
    },
];

export const LEVEL6_DROP = [
    {
        id: 5,
        img: level6_drop1,
        canDrop: true,
    },
    {
        id: 6,
        img: level6_drop2,
        canDrop: true,
    },
];

export const LEVELS = [
    {
        level: 1,
        completed: false,
        unlocked: true,
        answers_right: 0,
        title: 'Welke kleur heb ik?',
        title_single: 'Weet jij welke kleur ik heb?',
        subtitle: 'Sleep het figuurtje naar de juiste kleur.',
        overview_img: level1_drag3,
        drag: LEVEL1_DRAG,
        drop: LEVEL1_DROP,
    },
    {
        level: 2,
        completed: false,
        unlocked: false,
        answers_right: 0,
        title: 'Rarara, wat ben ik?',
        title_single: 'Kan jij raden wat ik ben?',
        subtitle: 'Sleep het plaatje naar het juiste antwoord.',
        overview_img: level2_drop4,
        drag: LEVEL2_DRAG,
        drop: LEVEL2_DROP,
    },
    {
        level: 3,
        completed: false,
        unlocked: false,
        answers_right: 0,
        title: 'Waar kom ik vandaan?',
        title_single: 'Weet jij waar ik vandaan kom?',
        subtitle: 'Sleep het plaatje naar het juiste antwoord.',
        overview_img: level3_drag1,
        drag: LEVEL3_DRAG,
        drop: LEVEL3_DROP,
    },
    {
        level: 4,
        completed: false,
        unlocked: false,
        answers_right: 0,
        title: 'Njammie of Jakkie?',
        title_single: 'Wat kan je nog eten en wat is al op?',
        subtitle: 'Sleep het plaatje naar de juiste smiley.',
        overview_img: level4_drag3,
        drag: LEVEL4_DRAG,
        drop: LEVEL4_DROP,
    },
    {
        level: 5,
        completed: false,
        unlocked: false,
        answers_right: 0,
        title: 'Wat past samen?',
        title_single: 'Weet jij wat bij elkaar hoort?',
        subtitle: 'Sleep het plaatje naar het juiste antwoord.',
        overview_img: level5_drag1,
        drag: LEVEL5_DRAG,
        drop: LEVEL5_DROP,
    },
    {
        level: 6,
        completed: false,
        unlocked: false,
        answers_right: 0,
        title: 'Elke dag of soms?',
        title_single: 'Wat mag je elke dag drinken?',
        subtitle: 'Sleep het plaatje naar het juiste antwoord.',
        overview_img: level6_drag1,
        drag: LEVEL6_DRAG,
        drop: LEVEL6_DROP,
    },
];
