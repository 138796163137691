import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile, isMobileOnly } from 'react-device-detect';

import HomePage from './pages/HomePage';
import LevelPage from './pages/LevelPage';
import Modal from './components/Modal';
import CustomPreview from './components/CustomPreview';

import { modal } from './redux/actions/modalActions';

import logo from './images/logo_kiddies.png';
import './css/tailwind.css';

const App = () => {
    const backend = isMobile ? TouchBackend : HTML5Backend;
    const modal_opts = useSelector((store: RootStateOrAny) => store.modalReducer);
    const levels = useSelector((store: RootStateOrAny) => store.levelReducer);
    const dispatch = useDispatch();

    const { visible, message, theme, currLevel } = modal_opts;
    const { fireworks, fireworks_single } = levels;

    const onClose = () => {
        dispatch(modal('', 'hide'));
    };

    // Show message that this game can only be played on tablets and desktop.
    if ( isMobileOnly ) {
        return (
            <div className="flex flex-col min-h-screen w-full justify-center items-center">
                <img src={logo} alt="Kiddies" className="max-w-logo pb-10" />
                <p className="px-4 text-center">Oeps, jouw scherm is te klein! Gebruik een tablet of computer om de Kiddies spelletjes te spelen.</p>
            </div>
        );
    }

    return (
        <DndProvider backend={backend}>
            <Router basename={process.env.PUBLIC_URL}>
                <Switch>
                    <Route exact path="/" component={HomePage}></Route>
                    <Route
                        path="/level/:id"
                        render={(routeProps) => {
                            let { id } = routeProps.match.params;

                            const currLevel = levels.levels.find((level: any) => {
                                return level.level === parseInt(id);
                            });

                            // Check if level is unlocked or exists.
                            if (!currLevel || !currLevel.unlocked) {
                                return <Redirect to="/" />;
                            } else {
                                return <LevelPage />;
                            }
                        }}
                    />
                </Switch>

                <Modal show={visible} message={message} theme={theme} currLevel={currLevel} onClose={onClose} />
            </Router>

            {isMobile && <CustomPreview />}

            <ReactCanvasConfetti
                fire={fireworks_single}
                angle={90}
                spread={Math.floor(Math.random() * (120 - 80) + 80)}
                startVelocity={55}
                particleCount={Math.floor(Math.random() * (40 - 20) + 20)}
                scalar={1.5}
                ticks={90}
                className="pointer-events-none fixed w-full h-full top-0 left-0"
            />
            <ReactCanvasConfetti
                fire={fireworks}
                angle={45}
                spread={60}
                startVelocity={55}
                particleCount={75}
                className="pointer-events-none fixed w-full h-full top-0 left-0"
            />
            <ReactCanvasConfetti
                fire={fireworks}
                angle={145}
                spread={60}
                startVelocity={55}
                particleCount={75}
                className="pointer-events-none fixed w-full h-full top-0 left-0"
            />
            <ReactCanvasConfetti
                fire={fireworks}
                angle={90}
                spread={100}
                startVelocity={45}
                particleCount={150}
                decay={0.92}
                scalar={2}
                className="pointer-events-none fixed w-full h-full top-0 left-0"
            />
        </DndProvider>
    );
};

export default App;
