import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useSound from 'use-sound';

import {
    updateDrag,
    updateDrop,
    completeLevel,
    unlockLevel,
    fireSingleConfetti,
    resetLevel,
    updateSpecial,
} from '../redux/actions/levelActions';
import { modal } from '../redux/actions/modalActions';

import DropZone from '../components/DropZone';
import Header from '../components/Header';
import DragZone from '../components/DragZone';
import SubHeader from '../components/SubHeader';
import LinkArrow from '../components/LinkArrow';
import LinkButton from '../components/LinkButton';

import correctSfx from '../sounds/choice_correct.mp3';
import completeSfx from '../sounds/levelup.mp3';
import { ReactComponent as Scribble } from './../images/scribble.svg';

const LevelPage = () => {
    let { id } = useParams();
    const levels = useSelector((store) => store.levelReducer.levels);
    const dispatch = useDispatch();

    const currLevel = levels.filter((level) => level.level === parseInt(id));
    const { drag, drop, title_single, subtitle, level, answers_right, completed } = currLevel[0];

    // Set next level var. Set unlocked false when next level > 6 (we only have 6 levels).
    const next = level + 1;
    let nextLevel;
    let unlocked;
    if (next <= 6) {
        nextLevel = levels.filter((level) => level.level === next);
        unlocked = nextLevel[0].unlocked;
    } else {
        unlocked = false;
    }

    const [isShuffled, setIsShuffled] = useState(false);
    const [dragShuffled, setDragShuffled] = useState([]);
    const [dropShuffled, setDropShuffled] = useState([]);
    const [playCorrect] = useSound(correctSfx, { volume: 0.9 });
    const [playComplete] = useSound(completeSfx, { volume: 1 });

    useEffect(() => {
        // Set drag and drop items. Shuffle array when level is opened.
        if (!isShuffled) {
            const shuffledDrag = shuffleArray(drag);
            const shuffledDrop = shuffleArray(drop);
            setDragShuffled(shuffledDrag);
            setDropShuffled(shuffledDrop);
            setIsShuffled(true);
        } else {
            setDragShuffled(drag);
            setDropShuffled(drop);
        }
    }, [drag, drop, level]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Complete current level and unlock next level when answer_right changes. Play sound and fire confetti when max amount of right answers is not reached yet.
        if (answers_right > 0) {
            if (answers_right === 4) {
                if (!completed) {
                    // Dispatch complete level and unlock next level events.
                    dispatch(completeLevel(level));
                    dispatch(unlockLevel(next));

                    // Play sound.
                    playComplete();
                }

                // Always show modal max amount of answers is reached.
                dispatch(modal('Joepie, je hebt alle antwoorden gevonden!', 'show', 'winner', level));
            } else {
                // Play correct sound.
                playCorrect();
                dispatch(fireSingleConfetti(level + answers_right));
            }
        }
    }, [answers_right]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateLevelItem = (level_id, drag_id, drop_id, new_drop_img) => {
        dispatch(updateDrag(level_id, drag_id));
        dispatch(updateDrop(level_id, drop_id, new_drop_img));
    };

    // Special = only 2 droppable items.
    const updateLevelItemSpecial = (level_id, drag_id, drop_id, new_drop_img) => {
        dispatch(updateSpecial(level_id, drag_id));
    };

    const replayLevel = () => {
        dispatch(resetLevel(level));
    };

    const shuffleArray = (array) => {
        let i = array.length - 1;
        for (i; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    };

    return (
        <>
            <Header level={`level${level}`} />

            <SubHeader variant="subpage" title={title_single} subtitle={subtitle} level={level} />

            <div className="container mx-auto mt-12">
                <div className="flex justify-center mb-12 relative">
                    {dragShuffled.map((item, i) => {
                        return (
                            <DragZone
                                key={i}
                                target={item.target}
                                img={item.img}
                                canDrag={item.canDrag}
                                level={id}
                                dragId={item.id}
                            />
                        );
                    })}

                    <Scribble className="text-yellow absolute w-32 h-32 left-0 top-full transform rotate-30 -translate-y-1/4 -translate-x-1/4" />
                </div>

                <div className="flex justify-center">
                    {dropShuffled.map((item, i) => {
                        return (
                            <DropZone
                                key={i}
                                img={item.img}
                                id={item.id}
                                canDrop={item.canDrop}
                                level={id}
                                updateItem={level === 6 || level === 4 ? updateLevelItemSpecial : updateLevelItem}
                            />
                        );
                    })}
                </div>
            </div>

            <div className="container mx-auto flex flex-col items-center mt-20 pb-20">
                <div className="flex justify-center">
                    <LinkArrow to={'/'} text={'Terug naar het overzicht'} />
                    {next <= 6 && unlocked && (
                        <LinkArrow to={`/level/${next}`} text={'Volgend spel'} className={'ml-6'} position={'right'} />
                    )}
                </div>

                {completed && <LinkButton className={'mt-4'} onClick={replayLevel} text={'Opnieuw spelen'} />}
            </div>
        </>
    );
};

export default LevelPage;
