import { HIDE_MODAL, SHOW_MODAL } from './constants';

/* Modal */
// Modal action creators.
export const showModal = (message: string, theme: string, currLevel: number) => ({
    type: SHOW_MODAL,
    payload: {
        message,
        theme,
        currLevel,
    },
});

export const hideModal = () => ({
    type: HIDE_MODAL,
});

// Modal actions.
export const modal = (message: '', action = 'hide', theme = 'winner', currLevel = 1) => {
    return (dispatch: any) => {
        if ('show' === action) {
            dispatch(showModal(message, theme, currLevel));
        }

        if ('hide' === action) {
            dispatch(hideModal());
        }
    };
};
