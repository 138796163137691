import { HIDE_MODAL, SHOW_MODAL } from '../actions/constants';

interface ModalState {
    visible: boolean;
    message: string;
    theme: string,
    currLevel: number,
}

type ModalActionType = {
    type: string;
    payload: {
        message: string;
        theme: string,
        currLevel: number,
    };
};

const initialState: ModalState = {
    visible: false,
    message: '',
    theme: 'winner',
    currLevel: 1,
};

const modalReducer = (state = initialState, action: ModalActionType) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                visible: true,
                message: action.payload.message,
                theme: action.payload.theme,
                currLevel: action.payload.currLevel,
            };
        case HIDE_MODAL:
            return {
                ...state,
                visible: false,
                message: '',
            };
        default:
            return state;
    }
};

export default modalReducer;
