import { LEVELS } from '../../Constants';
import {
    UPDATE_LEVEL_DRAG,
    UPDATE_LEVEL_DROP,
    COMPLETE_LEVEL,
    UNLOCK_LEVEL,
    SINGLE_FIREWORKS,
    RESET_LEVEL,
    UPDATE_LEVEL_SPECIAL,
} from '../actions/constants';

const initialState = {
    levels: LEVELS,
    fireworks: false,
    fireworks_single: false,
};

const levelReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_LEVEL_DRAG:
            return {
                ...state,
                levels: state.levels.map((item) => {
                    return item.level === parseInt(action.payload.level_id)
                        ? {
                              ...item,
                              answers_right: item.answers_right + 1,
                              drag: item.drag.map((drag) => {
                                  return drag.id === action.payload.drag_id ? { ...drag, canDrag: false } : drag;
                              }),
                          }
                        : item;
                }),
            };

        case UPDATE_LEVEL_DROP:
            return {
                ...state,
                levels: state.levels.map((item) => {
                    return item.level === parseInt(action.payload.level_id)
                        ? {
                              ...item,
                              drop: item.drop.map((drop) => {
                                  return drop.id === action.payload.drop_id
                                      ? { ...drop, img: action.payload.img, canDrop: false }
                                      : drop;
                              }),
                          }
                        : item;
                }),
            };

        case UPDATE_LEVEL_SPECIAL:
            return {
                ...state,
                levels: state.levels.map((item) => {
                    return item.level === parseInt(action.payload.level_id)
                        ? {
                              ...item,
                              answers_right: item.answers_right + 1,
                              drag: item.drag.map((drag) => {
                                  return drag.id === action.payload.drag_id ? { ...drag, canDrag: false } : drag;
                              }),
                          }
                        : item;
                }),
            };

        case RESET_LEVEL:
            return {
                ...state,
                levels: state.levels.map((item) => {
                    return item.level === parseInt(action.payload.level_id)
                        ? {
                              ...item,
                              completed: false,
                              answers_right: 0,
                              drag: action.payload.drag,
                              drop: action.payload.drop,
                          }
                        : item;
                }),
                fireworks: false,
            };

        case COMPLETE_LEVEL:
            return {
                ...state,
                levels: state.levels.map((item) => {
                    return item.level === parseInt(action.payload.level_id)
                        ? {
                              ...item,
                              completed: true,
                          }
                        : item;
                }),
                fireworks: action.payload.level_id,
            };

        case UNLOCK_LEVEL:
            return {
                ...state,
                levels: state.levels.map((item) => {
                    return item.level === parseInt(action.payload.level_id)
                        ? {
                              ...item,
                              unlocked: true,
                          }
                        : item;
                }),
            };

        case SINGLE_FIREWORKS:
            return {
                ...state,
                fireworks_single: action.payload.id,
            };

        default:
            return state;
    }
};
export default levelReducer;
