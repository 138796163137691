import React from 'react';

import LevelGridItem from './LevelGridItem';

import melkfles from '../images/melk-en-aarbei.png';
import ballon from '../images/tekstballon.png';

const LevelGrid = ({ levels }) => {
    return (
        <div className="container mx-auto px-4 pt-12 flex items-start flex-col md:flex-row">
            <div className="flex flex-wrap max-w-grid -mx-2">
                {levels.map((item, i) => {
                    return <LevelGridItem key={i} item={item} />;
                })}
            </div>

            <div className="-ml-16 -mt-8 relative pointer-events-none self-end md:self-auto">
                <img className="absolute left-4 top-4 lg:top-16 lg:left-24" alt="Start met spelletje 1 en eindig bij 6" src={ballon} />
                <img className="max-w-gif lg:max-w-fles" alt="Melk en aardbei - Kiddies" src={melkfles} />
            </div>
        </div>
    );
};

export default LevelGrid;
