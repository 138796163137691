import React from 'react';
import { useSelector } from 'react-redux';

import Header from '../components/Header';
import LevelGrid from '../components/LevelGrid';
import SubHeader from '../components/SubHeader';

const HomePage = () => {
    const levels = useSelector((store) => store.levelReducer.levels);

    return (
        <>
            <Header />

            <SubHeader
                variant={'home'}
                subtitle={'Laat je Kiddie spelenderwijs gezonde voeding ontdekken.'}
                title={'Kijk wat ik al'}
                title_more={'eet'}
            />

            <LevelGrid levels={levels} />
        </>
    );
};

export default HomePage;
