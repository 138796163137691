import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Right } from './../images/right-arrow.svg';
import { ReactComponent as Left } from './../images/left-arrow.svg';

const LinkArrow = ({ to = '/', position = 'left', className = '', text = '', onClick = () => null }) => {
    return (
        <Link to={to} className={`${className}`} onClick={onClick}>
            <div className="flex">
                {position === 'left' && (
                    <div className="bg-lightblue rounded-full w-triangle flex items-center justify-center">
                        <span className="text-yellow leading-none pr-1">
                            <Left className="w-5 h-5" />
                        </span>
                    </div>
                )}

                <span className={'bg-white text-lightblue px-4 py-3 leading-none rounded-full'}>{text}</span>

                {position === 'right' && (
                    <div className="bg-lightblue rounded-full w-triangle flex items-center justify-center">
                        <span className="text-yellow leading-none pl-1">
                            <Right className="w-5 h-5" />
                        </span>
                    </div>
                )}
            </div>
        </Link>
    );
};

export default LinkArrow;
