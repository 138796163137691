import { combineReducers } from 'redux';

import modalReducer from './modalReducer';
import levelReducer from './levelReducer';

const rootReducer = combineReducers({
    modalReducer,
    levelReducer,
});

export default rootReducer;
