import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import LinkArrow from './LinkArrow';
import LinkButton from './LinkButton';
import level1 from './../images/level1/kaaskop.png';
import level2 from './../images/level5/appel.png';
import level3 from './../images/level4/_ei.png';
import level4 from './../images/level3/yoghurt.png';
import level5 from './../images/level2/_framboos.png';
import level6 from './../images/level6/_water.png';

import { resetLevel } from '../redux/actions/levelActions';

const Modal = ({ show, message, onClose, theme, currLevel }) => {
    let classes = show ? 'pointer-events-visible opacity-1' : 'pointer-events-none opacity-0';
    const [figureSrc, setFigureSrc] = useState(level1);
    const [nextLevel, setNextLevel] = useState(1);
    const dispatch = useDispatch();

    useEffect(() => {
        setNextLevel(parseInt(currLevel) + 1);

        // Set image source based on current level for modal.
        const setNextImageSource = (currLevel) => {
            switch (parseInt(currLevel)) {
                case 1:
                    setFigureSrc(level1);
                    break;
                case 2:
                    setFigureSrc(level2);
                    break;
                case 3:
                    setFigureSrc(level3);
                    break;
                case 4:
                    setFigureSrc(level4);
                    break;
                case 5:
                    setFigureSrc(level5);
                    break;
                case 6:
                    setFigureSrc(level6);
                    break;
                default:
                    setFigureSrc(level1);
                    break;
            }
        };
        setNextImageSource(currLevel);
    }, [currLevel]);

    const stopProp = (e) => {
        e.stopPropagation();
    };

    const replayLevel = () => {
        onClose();
        dispatch(resetLevel(currLevel));
    };

    return (
        <>
            <div
                className={`fixed left-0 top-0 w-full h-full flex items-center justify-center bg-black bg-opacity-25 transition-opacity ${classes}`}
                onClick={onClose}
            >
                <div
                    className="bg-white modal__bg flex flex-col relative items-center justify-center pt-20 pb-12 px-20 text-center"
                    onClick={stopProp}
                >
                    <img
                        alt="Kaas met vraagteken"
                        src={figureSrc}
                        className="max-w-griditem max-h-griditem absolute -top-28"
                    />
                    <p className="text-lightblue">{message}</p>

                    <div className="mt-8">
                        {theme === 'winner' ? (
                            <>
                                <div className="flex justify-center">
                                    <LinkArrow onClick={onClose} to={'/'} text={'Speel een ander spel'} />

                                    {nextLevel <= 6 && (
                                        <LinkArrow
                                            onClick={onClose}
                                            to={`/level/${nextLevel}`}
                                            text={'Volgend spel'}
                                            className={'ml-6'}
                                            position={'right'}
                                        />
                                    )}
                                </div>

                                <LinkButton className={'mt-4'} onClick={replayLevel} text={'Opnieuw spelen'} />
                            </>
                        ) : (
                            <LinkArrow onClick={onClose} to={''} text={'Sluiten'} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
