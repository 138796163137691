import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { modal } from '../redux/actions/modalActions';

const LevelGridItem = ({ item }) => {
    const dispatch = useDispatch();
    const { level, title, overview_img, completed, unlocked } = item;

    const checkUnlocked = (e) => {
        if (!unlocked) {
            e.preventDefault();
            dispatch(modal('Je moet eerst de vorige spelletjes uitspelen!', 'show', 'error', level));
        }
    };

    return (
        <div className={`flex-grid p-2 mb-8 relative ${!unlocked ? 'opacity-50' : 'opacity-100'}`}>
            <Link onClick={checkUnlocked} to={`/level/${level}`}>
                <div className="absolute left-2/4 top-0 transform -translate-x-2/4 w-12 h-12 bg-yellow rounded-full flex items-center justify-center leading-none -top-3.5">
                    <span className="relative top-0.5 text-lightblue text-2xl">{level}</span>
                </div>
                <div className="border-6 border-yellow min-h-griditem p-4">
                    <img
                        src={overview_img}
                        alt={title}
                        className={'h-full mx-auto max-h-griditem pt-2'}
                    />

                    {!unlocked && <div className="absolute right-0 bottom-1 pb-14 pr-6">❌</div>}

                    {completed && <div className="absolute right-0 bottom-1 pb-14 pr-6">✔️</div>}
                </div>
                <p className="text-center text-lg text-darkblue font-title mt-3">{title}</p>
            </Link>
        </div>
    );
};

export default LevelGridItem;
