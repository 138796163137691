import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers/rootReducer';

const persistConfig = {
    key: 'kiddies-game-1.0.3',
    storage,
    whitelist: ['levelReducer'],
};

const persistedRootReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedRootReducer, composeWithDevTools(applyMiddleware(thunk)));
export const persistor = persistStore(store);
