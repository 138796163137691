import React from 'react';
import { useDrag } from 'react-dnd';
import { DnDTypes } from '../Constants';

const DragZone = ({ target, img, canDrag, dragId }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: DnDTypes.DRAG,
        item: { target, dragId, img },
        collect: (monitor) => ({
            item: target,
            isDragging: !!monitor.isDragging(),
        }),
        canDrag: () => {
            return canDrag;
        },
    });

    return (
        <div
            ref={dragRef}
            className={`transition-all min-h-griditem2 items-center justify-center flex flex-auto25 p-4 ${
                isDragging ? 'opacity-50' : ''
            } ${!canDrag ? 'bg-gray-100 opacity-20' : ''}`}
        >
            <img className="max-h-griditem max-w-griditem" alt="Level drag" src={img} />
        </div>
    );
};

export default DragZone;
