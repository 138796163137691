import React from 'react';
import { useDrop } from 'react-dnd';
import useSound from 'use-sound';

import { DnDTypes } from '../Constants';

import wrongSfx from '../sounds/choice_wrong.mp3';

const DropZone = ({ id, img, canDrop, level, updateItem }) => {
    const [playWrong] = useSound(wrongSfx, { volume: 0.5 });

    const [{ isOver }, dropRef] = useDrop({
        accept: DnDTypes.DRAG,
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
        canDrop: () => {
            return canDrop;
        },
        drop: (item, monitor) => {
            if (item.target === id) {
                // Update drag/drop item.
                updateItem(level, item.dragId, id, item.img);
            } else {
                playWrong();
            }
        },
    });

    const preventDefault = (e) => {
        e.preventDefault();
    }

    return (
        <div
            ref={dropRef}
            onDrop={preventDefault}
            className={`transition-all items-center min-h-griditem2 justify-center flex flex-auto25 p-4 ${
                isOver && canDrop ? 'opacity-50' : ''
            } ${!canDrop ? 'opacity-100' : ''}`}
        >
            <img className="max-h-griditem max-w-griditem" alt="Level drop" src={img} />
        </div>
    );
};

export default DropZone;
