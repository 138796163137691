import React from 'react';
import { usePreview } from 'react-dnd-preview';

const CustomPreview = () => {
    const { display, item, style } = usePreview();

    if (!display) {
        return null;
    }

    return (
        <div className='' style={style}>
             <img className="max-h-griditem max-w-griditem" alt="Level drag" src={item.img} />
        </div>
    );
};

export default CustomPreview;