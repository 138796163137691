/* ACTION TYPES */
// Modal.
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

// Levels
export const UPDATE_LEVEL_DRAG = 'UPDATE_LEVEL_DRAG';
export const UPDATE_LEVEL_DROP = 'UPDATE_LEVEL_DROP';
export const UPDATE_LEVEL_SPECIAL = 'UPDATE_LEVEL_SPECIAL';
export const COMPLETE_LEVEL = 'COMPLETE_LEVEL';
export const UNLOCK_LEVEL = 'UNLOCK_LEVEL';
export const RESET_LEVEL = 'RESET_LEVEL';
export const SINGLE_FIREWORKS = 'SINGLE_FIREWORKS';