import React from 'react';

const LinkButton = ({ position = 'left', className = '', text = '', onClick }) => {
    return (
        <>
            <button
                className={`bg-white text-lightblue px-4 py-3 leading-none rounded-full ${className}`}
                onClick={onClick}
            >
                {text}
            </button>
        </>
    );
};

export default LinkButton;
