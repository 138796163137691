import React from 'react';
import { Link } from 'react-router-dom';

import logo from './../images/logo_kiddies.png';
import nice_logo from './../images/nice_logo.png';
import lvl1 from './../images/gif_ei.gif';
import lvl2 from './../images/gif_appel.gif';
import lvl3 from './../images/gif_brood.gif';
import lvl4 from './../images/gif_yoghurt.gif';
import lvl5 from './../images/gif_peer.gif';
import lvl6 from './../images/gif_ui.gif';

const Header = ({ level = 'home' }) => {
    return (
        <>
            <div className="bg-yellow">
                <div className="container mx-auto px-4 flex relative overflow-hidden">
                    <div className="pb-8">
                        <Link to={'/'}>
                            <img src={logo} alt="Kiddies" className="max-w-logo" />
                        </Link>
                    </div>

                    <div className="pl-10 py-6 pt-20 relative z-10">
                        <a target="_blank" className="absolute top-0" href="https://www.nice-info.be/"><img src={nice_logo} alt="NICE - Voedingsinformatiecentrum" className="max-w-nice absolute top-0" /></a>
                        <h2 className="text-darkblue text-3xl leading-8">
                            Wat je als kleuter eet, <br /> heb je voor je leven beet.
                        </h2>
                        <h4 className="text-lg text-lightblue pt-2">3-6 jaar</h4>
                    </div>

                    {level === 'level1' && (
                        <div className="absolute right-0 -top-32">
                            <img src={lvl1} alt="Kiddies level1" className="max-w-gif" />
                            <div className="w-full h-full bg-yellow absolute right-0 top-0 opacity-50"></div>
                        </div>
                    )}

                    {level === 'level2' && (
                        <div className="absolute right-0 -top-32">
                            <img src={lvl2} alt="Kiddies level2" className="max-w-gif" />
                            <div className="w-full h-full bg-yellow absolute right-0 top-0 opacity-50"></div>
                        </div>
                    )}

                    {level === 'level3' && (
                        <div className="absolute right-0 -top-8">
                            <img src={lvl3} alt="Kiddies level2" className="max-w-gif" />
                            <div className="w-full h-full bg-yellow absolute right-0 top-0 opacity-50"></div>
                        </div>
                    )}

                    {level === 'level4' && (
                        <div className="absolute right-0 -top-24">
                            <img src={lvl4} alt="Kiddies level4" className="max-w-gif" />
                            <div className="w-full h-full bg-yellow absolute right-0 top-0 opacity-50"></div>
                        </div>
                    )}

                    {level === 'level5' && (
                        <div className="absolute right-0 -top-8">
                            <img src={lvl5} alt="Kiddies level4" className="max-w-gif" />
                            <div className="w-full h-full bg-yellow absolute right-0 top-0 opacity-50"></div>
                        </div>
                    )}

                    {level === 'level6' && (
                        <div className="absolute right-0 -top-32">
                            <img src={lvl6} alt="Kiddies level6" className="max-w-gif" />
                            <div className="w-full h-full bg-yellow absolute right-0 top-0 opacity-50"></div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Header;
