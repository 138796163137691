import React from 'react';

const SubHeader = ({ variant, title, subtitle, title_more, level }) => {
    return (
        <div className="container mx-auto px-4 pt-8">
            {variant === 'home' && (
                <>
                    <p className="text-lightblue text-xl mb-2">{subtitle}</p>
                    <h1 className="text-5xl text-darkblue">
                        {title}
                        <span className="relative ml-12 special-w">{title_more}</span>
                    </h1>
                </>
            )}

            {variant === 'subpage' && (
                <>
                    <h1 className="text-yellow text-5xl flex items-center">
                       {level}. {title}
                    </h1>
                    <p className="text-lightblue mt-4 text-xl">{subtitle}</p>
                </>
            )}
        </div>
    );
};

export default SubHeader;
