import {
    LEVEL1_DRAG,
    LEVEL1_DROP,
    LEVEL2_DRAG,
    LEVEL2_DROP,
    LEVEL3_DRAG,
    LEVEL3_DROP,
    LEVEL4_DRAG,
    LEVEL4_DROP,
    LEVEL5_DRAG,
    LEVEL5_DROP,
    LEVEL6_DRAG,
    LEVEL6_DROP,
} from '../../Constants';
import {
    COMPLETE_LEVEL,
    UPDATE_LEVEL_DRAG,
    UPDATE_LEVEL_DROP,
    UNLOCK_LEVEL,
    SINGLE_FIREWORKS,
    RESET_LEVEL,
    UPDATE_LEVEL_SPECIAL,
} from './constants';

/* Levels */
// Level action creators.
export const updateDragForLevel = (level_id, drag_id) => ({
    type: UPDATE_LEVEL_DRAG,
    payload: {
        level_id,
        drag_id,
    },
});

export const updateDropForLevel = (level_id, drop_id, img) => ({
    type: UPDATE_LEVEL_DROP,
    payload: {
        level_id,
        drop_id,
        img,
    },
});

export const updateSpecialFor = (level_id, drag_id) => ({
    type: UPDATE_LEVEL_SPECIAL,
    payload: {
        level_id,
        drag_id,
    },
});

export const completeLevelFor = (level_id) => ({
    type: COMPLETE_LEVEL,
    payload: {
        level_id,
    },
});

export const unlockLevelFor = (level_id) => ({
    type: UNLOCK_LEVEL,
    payload: {
        level_id,
    },
});

export const resetLevelFor = (level_id) => {
    let drag, drop;
    switch (level_id) {
        case 1:
            drag = LEVEL1_DRAG;
            drop = LEVEL1_DROP;
            break;
        case 2:
            drag = LEVEL2_DRAG;
            drop = LEVEL2_DROP;
            break;
        case 3:
            drag = LEVEL3_DRAG;
            drop = LEVEL3_DROP;
            break;
        case 4:
            drag = LEVEL4_DRAG;
            drop = LEVEL4_DROP;
            break;
        case 5:
            drag = LEVEL5_DRAG;
            drop = LEVEL5_DROP;
            break;
        case 6:
            drag = LEVEL6_DRAG;
            drop = LEVEL6_DROP;
            break;
        default:
            break;
    }

    return {
        type: RESET_LEVEL,
        payload: {
            level_id,
            drag,
            drop,
        },
    };
};

// Level actions.
export const updateDrag = (level_id, drag_id) => {
    return (dispatch) => {
        dispatch(updateDragForLevel(level_id, drag_id));
    };
};

export const updateDrop = (level_id, drop_id, img) => {
    return (dispatch) => {
        dispatch(updateDropForLevel(level_id, drop_id, img));
    };
};

export const updateSpecial = (level_id, drag_id) => {
    return (dispatch) => {
        dispatch(updateSpecialFor(level_id, drag_id));
    };
};

export const completeLevel = (level_id) => {
    return (dispatch) => {
        dispatch(completeLevelFor(level_id));
    };
};

export const unlockLevel = (next_level) => {
    return (dispatch) => {
        dispatch(unlockLevelFor(next_level));
    };
};

export const resetLevel = (level_id) => {
    return (dispatch) => {
        dispatch(resetLevelFor(level_id));
    };
};

export const fireSingleConfetti = (id) => {
    return (dispatch) => {
        dispatch({ type: SINGLE_FIREWORKS, payload: { id } });
    };
};
